/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import StaticLayout from '../components/StaticLayout';
import './privacy-policy-cookie-restriction-mode.module.css';

const tos = {
  noNum: [
    {
      content: `ООО «СВЕЖАЯ ДОСТАВКА», именуемое далее «Moscowfresh», публикует настоящее Пользовательское соглашение, представляющее собой публичную оферту в отношении пользователей портала moscowfresh.ru (далее «Пользователь»).`,
      id: 1,
    },
    {
      content: `Перед началом использования портала moscowfresh.ru (далее «Сервис») просим Вас внимательно ознакомиться с изложенными ниже условиями пользования. Используя Сервис, Вы понимаете изложенные в настоящем Пользовательском соглашении условия и обязуетесь соблюдать их. Если Вы не согласны с какими-либо пунктами Пользовательского соглашения, либо они Вам не ясны, то Вы обязаны отказаться от использования Сервиса moscowfresh.ru. Использование Сервиса без согласия с условиями настоящего Пользовательского соглашения не допускается.`,
      id: 2,
    },
    {
      content: `Акцептом настоящего Пользовательского соглашения является использование функций Сервиса в любой форме. Настоящее Пользовательское соглашение в отношении конкретного Пользователя вступает в силу с момента его акцепта Пользователем. Не допускается акцепт настоящего Пользовательского соглашения под условиями, либо с оговорками.`,
      id: 3,
    },
  ],
  num: [
    {
      title: '1. Общие положения',
      content: [
        '1.1. ООО «СВЕЖАЯ ДОСТАВКА» (далее — Moscowfresh) предлагает пользователю сети Интернет (далее — Пользователь) использовать сервис Moscowfresh, доступный по адресу http://www.moscowfresh.ru (далее — «Сервис»). Moscowfresh предоставляет Пользователю возможность приобрести дистанционным способом товары.',
        '1.2. Начиная использовать Сервис/его отдельные функции, Пользователь считается принявшим настоящие Условия использования сервиса в полном объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Условий, Пользователь не вправе использовать Сервис.',
        '1.3. Настоящие Условия могут быть изменены руководством Moscowfresh без какого-либо специального уведомления, новая редакция Условий вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем абзаце адресу, если иное не предусмотрено новой редакцией Условий. Действующая редакция Условий всегда находится на странице по адресу: http://www.moscowfresh.ru/privacy-policy-cookie-restriction-mode/. При осуществлении каждого заказа Пользователь обязуется внимательно прочитать актуальную вресию настоящего Пользователького соглашения. В случае если были внесены какие-либо изменения в настоящие Условия, с которыми Пользователь не согласен, он обязан прекратить использование Сервиса.',
      ],
      id: 1,
    },
    {
      title: '2. Использование Сервиса',
      content: [
        '2.1. Сервис предоставляется Пользователю для личного некоммерческого использования.',
        '2.2. Moscowfresh оставляет за собой право ограничить доступ Пользователя к Сервису в случае нарушения им положений действующего законодательства или настоящего Пользовательского соглашения.',
        '2.3. Пользователь имеет право запросить у Сервиса более подробную информацию о товарах, такую как состав и место производства, путем отправки запроса на электронную почту client@moscowfresh.ru.',
        '2.4. Заинтересованный в представленных на Сервисе товарах Пользователь через форму на сайте отправляет запрос с перечнем товаров и контактной информацией, а Сервис направляет Пользователю подтверждение о получении запроса посредством смс и обрабатывает запрос на реализацию дистанционным образом заказанных товаров в установленный Пользователем срок.',
        '2.5. В случае недовольства качеством оказанных услуг, Пользователь обязуется в течение 24 часов с момента наступления времени доставки сообщить по e-mail адресу client@moscowfresh.ru о своём желании вернуть уплаченные денежные средства и указать причину отказа от заказа или отдельных позиций заказа.',
        '2.6. Сервис содержит материалы, охраняемые авторским правом, товарные знаки и иные охраняемые законом материалы, включая, но не ограничиваясь: тексты, видео-ролик, фотографии, графические изображения. Пользователь не имеет права вносить изменения, публиковать, передавать третьим лицам, участвовать в продаже или уступке, создавать производные продукты или иным образом использовать, частично или полностью, содержание Сайта.',
      ],
      id: 2,
    },
    {
      title: '3. Оплата',
      content: [
        '3.1. Сервис предоставляет возможность оплаты выбранных товаров онлайн с использованием электронной платежной системы ООО НКО "Яндекс.Деньги". При оплате на карте Пользователя "замораживается" (холдируется) на 10% больше суммы заказа на случай увеличения стоимости по весовым позициям, а в момент завершения доставки списывается точная стоимость, а разница между замороженной суммой и точной стоимостью "размораживается" (снова становится доступна). Время "разморозки" варьируется от 1 до 30 дней в зависимости от банка. Moscowfresh не имеет возможности ускорить срок возврата денежных средств, так как он зависит только от банка, выпустившим банковскую карту Пользователя.',
        '3.2. Сервис не хранит данные карты Пользователя и не занимается обработкой платежей.',
      ],
      id: 3,
    },
    {
      title: '4. Прочие условия использования Сервиса. Отдельные функции Сервиса',
      content: [
        '4.1. В случае, если товар продается поштучно, и цена зависит от веса, итоговая стоимость единицы товара может отличаться от стоимости единицы товара, указанной на сайте. При этом цена за килограмм будет соответствовать цене за килограмм, на основе которой рассчитана цена единицы товара на сайте.',
        '4.2. Пользователь соглашается с тем, что при наличии в заказе товаров, стоимость которых зависит от веса, изначально озвученная на Сервисе стоимость выбранных товаров может измениться. Точная стоимость становится известна только после сборки заказа. Пользователь также соглашается с тем, что при изменении стоимости выбранных товаров менее чем на 10% Сервис не согласовывает изменения с Пользователем.',
        '4.3. Пользователь соглашается с тем, что на момент исполнения его запроса некоторых товаров может не быть в наличии. Сервис обязуется связаться с Пользователем для согласования изменения условий заказа или передать информацию об отсутствии запрошенной позиции через курьера.',
        '4.4. Если стоимость выбранных товаров не превышает 5000 рублей, стоимость доставки в пределах МКАД составляет 290 рублей, которые дополнительно включаются в счет. Если стоимость выбранных товаров превышает 5000 руб, стоимость доставки в пределах МКАД в счет не включается. О стоимости доставки за МКАД Пользователь обязуется ознакомиться на странице: https://moscowfresh.ru/uslovija-dostavki',
        '4.5. На Сервисе действует программа накопления бонусных баллов. Пользователь соглашается с тем, что бонусные баллы начисляются только если на момент заказа Пользователь был авторизован (вошел в личный кабинет путем ввода логина и пароля на Сервисе) и пропадают через месяц с момента получения. Пользователь также соглашается с тем, что получение скидки на сумму накопленных бонусных баллов возможно только если количество баллов превышает стоимость заказа с учетом доставки.',
      ],
      id: 4,
    },
    {
      title: '5. Согласие на обработку персональных данных',
      content: [
        '5.1. Политика в отношении обработки персональных данных направлена на защиту прав и свобод Пользователей, персональные данные которых обрабатывает Сервис.',
        '5.2. С целью обработки запроса о спросе на представленные на Сервисе товары, Сервис запрашивает у Пользователя следующую информацию: имя, адрес электронной почты, номер контактного телефона, адрес доставки товара. При этом Пользователь соглашается на обработку указанных персональных данных Moscowfresh.',
        '5.3. Сервис получает персональные данные непосредственно у субъектов персональных данных. Сервис обрабатывает персональные данные автоматизированным и неавтоматизированным способами, с использованием средств вычислительной техники и без использования таких средств. Под обработкой персональных данных понимается любое действие (операция) или совокупность действий (операций), совершаемых с персональными данными, включая сбор, запись, систематизацию, хранение, использование, передачу, удаление персональных данных.',
        '5.4. Moscowfresh оставляет за собой право осуществлять информационные, в том числе рекламные сообщения, на электронную почту Пользователя. Пользователь вправе отказаться от получения рекламной и другой информации без объяснения причин отказа, перейдя по ссылке внизу сообщения от Сервиса.',
        '5.5. Полный или частичный отзыв настоящего согласия осуществляется путем представления в Moscowfresh письменного заявления. В этом случае Moscowfresh прекращает обработку персональных данных и уничтожает персональные данные в течение десяти рабочих дней со дня получения письменного заявления.',
        '5.6. Базы данных информации, содержащей персональные данные Пользователей, находятся на теооритории Российской Федирации.',
      ],
      id: 5,
    },
    {
      title: '6. Решение спорных вопросов',
      content: [
        '6.1. Настоящий договор в форме Пользовательского соглашения заключен Сторонами в офертно-акцептной форме без подписания отдельного письменного Документа и регулируется нормами российского законодательства. Все возможные споры по поводу данного соглашения разрешаются согласно нормам действующего российского законодательства.',
        '6.2. Все возникшие между Сторонами споры разрешаются путем переговоров.',
        '6.3. Стороны пришли к соглашению, что претензионный (досудебный) порядок урегулирования споров, возникших в результате исполнения настоящего соглашения, является для них обязательным.',
        '6.4. Претензии по настоящему соглашению могут направляться Сторонами по электронной почте.',
        '6.5. Стороны договорились, что срок ответа на претензию составляет не более 10 календарных дней.',
        '6.6. В случае возникновения судебного спора, спор подлежит рассмотрению по выбору истца по месту нахождения ООО «СВЕЖАЯ ДОСТАВКА» или месту жительства/пребывания истца.',
      ],
      id: 6,
    },
    {
      title: '7. Реквизиты ',
      content: [
        <Fragment>
          Наименование: ООО «СВЕЖАЯ ДОСТАВКА»

          Юридический адрес:
           123557, г.Москва, вн.тер.г. Муниципальный округ Пресненский, ул Малая Грузинская, д. 28, помещ. 6/14
          <br />
          Почтовый адрес:
           121059, г. Москва, набережная Бережковская, д. 20 , стр. 73
          <br />
          Фактический адрес:
           121059, г. Москва, набережная Бережковская, д. 20 , стр. 73
          <br />
          ИНН/КПП:
           9703028119/ 770301001
          <br />
          ОГРН:
           1217700091443
          <br />
          ИФНС:
           №3 по г. Москве
          <br />
          Расчетный счет:
           40702810138000326983
          <br />
          БАНК:
           ПАО СБЕРБАНК
          <br />
          БИК:
           044525225
          <br />
          К/с:
           30101810400000000225
          <br />
          ОКПО:
           47418600
          <br />
          ОКВЭД:
           47.91.2
          <br />
          Генеральный директор:
           Дердерян Елена Баруковна
          <br />
          Главный бухгалтер:
           Дердерян Елена Баруковна
          <br />
          Телефон:
           8 (495) 761-55-77
          <br />
          Почта:
           hello@myfresh.ru
        </Fragment>,
      ],
      id: 7,
    },
  ],
};

const PrivacyPolicy = () => (
  <StaticLayout>
    <Helmet title="Условия использования сервиса" />
    <div styleName="wrapper">
      <h1 styleName="h1">Условия использования сервиса </h1>
      {tos.noNum.map(t => (
        <Fragment key={t.id}>
          <p styleName="p">{t.content}</p>
          <p styleName="p">
            <br />
          </p>
        </Fragment>
      ))}
      {tos.num.map(t => (
        <Fragment key={t.id}>
          <p styleName="p">
            <strong>{t.title}</strong>
          </p>
          {t.content.map(c => (
            <p styleName="p">{c}</p>
          ))}
          <p styleName="p">
            <br />
          </p>
        </Fragment>
      ))}
    </div>
  </StaticLayout>
);

export default PrivacyPolicy;
